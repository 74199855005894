import { useEffect, useState } from 'react'
import { Switch, Route, useHistory } from 'react-router-dom'
import { ErrorBoundary, useErrorHandler } from 'react-error-boundary'

import FallbackComponent from 'components/errors/FallbackComponent'
import Steps from 'components/Steps'
import { confirmFlow } from 'actions/verification'
import { flowError } from 'assets/js/utils/error-names'
import useLoader from 'hooks/use-loader'

import { propTypes } from './OnboardingScreenProps'

import basicRouting from 'routing'

import './styles.scss'

const OnboardingScreen = ({ steps = [] }) => {
  const history = useHistory()
  const [activeStep, setActiveStep] = useState(0)
  const { BaseLoader, isLoading, loaderOn } = useLoader()
  const handleError = useErrorHandler()

  const handleRedirect = async (steps) => {
    let stepToStart
    if (!steps.length) return
    const firstInProgress = steps.find((step) => step.status === 'in_progress')
    if (firstInProgress) stepToStart = firstInProgress
    else {
      const firstPending = steps.find((step) => step.status === 'pending')
      if (firstPending) stepToStart = firstPending
      else {
        // complete flow
        loaderOn()
        try {
          const result = await confirmFlow()
          sessionStorage.removeItem('IDVToken')
          window.location = result.redirect_url
        } catch (e) {
          e.name = flowError
          handleError(e)
        }
      }
    }
    // flow in progress, go to active step page
    if (stepToStart) {
      setActiveStep(stepToStart.position - 1)
      history.push('/' + stepToStart.type)
    }
  }

  useEffect(() => {
    handleRedirect(steps)
  }, [steps])

  return (
    <div className="onboard">
      <BaseLoader isActive={isLoading} />
      <ErrorBoundary FallbackComponent={FallbackComponent}>
        <Switch>
          {basicRouting.map(({ path, component: Component }) => {
            return (
              <Route
                key={path}
                path={path}
                render={() => {
                  return <Component activeStep={steps[activeStep]} />
                }}
              />
            )
          })}
        </Switch>
      </ErrorBoundary>
      <div className="onboard__steps">
        {steps && <Steps steps={steps.length} current={activeStep} />}
      </div>
    </div>
  )
}

OnboardingScreen.propTypes = propTypes

export default OnboardingScreen
