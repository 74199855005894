import { useRef, useState } from 'react'
import clsx from 'clsx'
import { imageLoadStatuses as statuses } from 'assets/js/utils/statuses'

import './styles.scss'

const BaseImage = ({ src, alt = '' }) => {
  const fallback = '/img/doc.png'
  const [loadedStatus, setLoadedStatus] = useState(statuses.pending)
  const image = useRef(null)

  return (
    <div className="base-image">
      {(loadedStatus === statuses.pending ||
        loadedStatus === statuses.error) && (
        <span className="base-image__loader" />
      )}
      <img
        ref={image}
        onLoad={() => setLoadedStatus(statuses.success)}
        onError={() => setLoadedStatus(statuses.error)}
        src={src || fallback}
        className={clsx({
          'base-image__image': true,
          'base-image__image--visible': loadedStatus === statuses.success
        })}
        alt={alt}
      />
      {(loadedStatus === statuses.error ||
        loadedStatus === statuses.fallback) && (
        <img
          src={fallback}
          onLoad={() => setLoadedStatus(statuses.fallback)}
          alt="document icon"
          className={clsx({
            'base-image__fallback': true,
            'base-image__fallback--visible': loadedStatus === statuses.fallback
          })}
        />
      )}
    </div>
  )
}

export default BaseImage
