import { useRef, useState, useEffect } from 'react'
import cover from 'assets/js/helpers/fit-canvas'

/*
  Provides refs and methods to use canvas with object-fit: cover imitation.
  Requires the component to use ref={canvas} on HTMLCanvasElement.
 */

const useCoverCanvas = (ratio) => {
  const canvas = useRef(null)
  const ctx = useRef(null)

  const [canvasParams, updateCanvasParams] = useState({
    imageWidth: 0,
    imageHeight: 0,
    offsetX: 0,
    offsetY: 0
  })

  useEffect(() => {
    ctx.current = canvas.current.getContext('2d')
  })

  const setCanvasParams = ({ streamWidth, streamHeight }) => {
    /* 
     streamWidth, streamHeight - sizes of video source to be painted
    */

    // calculate optimal size of canvas
    let canvasWidth = streamWidth
    let canvasHeight = streamHeight
    if (streamWidth > streamHeight) {
      canvasWidth = canvasHeight * ratio
    } else canvasHeight = canvasWidth / ratio

    canvas.current.width = canvasWidth
    canvas.current.height = canvasHeight

    // apply cover imitation
    const canvasParams = {
      ...cover(canvasWidth, canvasHeight, streamWidth, streamHeight)
    }
    updateCanvasParams(canvasParams)

    return canvasParams
  }

  return {
    canvas,
    ctx,
    canvasParams,
    setCanvasParams
  }
}

export default useCoverCanvas
