import { useReducer } from 'react'
import { stepStatuses } from 'assets/js/utils/statuses'

/**
 * Split UI logic and camera init.
 * 
 * `isVisible` property defines whether DocumentBody is displayed in UI
 * `status` propery enables switch between ongoing/complete status
 * which effects CameraStream behavior:
 * 		we need to render CameraStream (and make call to MediaStream API) only if step is not falsy;
 *    otherwise we get unnecessary calls to MS API before any data with step status has arrived.
 
* So, step status is initially set to null.
 * After app was init and status is set, this state would be preserved
 * between re-renders to prevent from camera re-mount,
 * while DocumentBody screen would be hidden before each data update
 * to avoid 'blinking' of irrelevant UI between screens
 * and then shown only if step is still ongoing.
 */

export const actionTypes = {
  init: 'INIT',
  hide: 'HIDE',
  push: 'PUSH',
  complete: 'COMPLETE'
}

const initialState = {
  isVisible: false,
  status: null
}

const stateReducer = (state, action) => {
  switch (action) {
    case actionTypes.init:
      return {
        ...initialState
      }
    case actionTypes.hide:
      return {
        ...state,
        isVisible: false
      }
    case actionTypes.push:
      return {
        isVisible: true,
        status: stepStatuses.ongoing
      }
    case actionTypes.complete:
      return {
        isVisible: false,
        status: stepStatuses.complete
      }
    default:
      throw Error('Not valid action was passed to stateReducer.')
  }
}

const useStepStateReducer = () => {
  return useReducer(stateReducer, initialState)
}

export default useStepStateReducer
