export const imageLoadStatuses = {
  pending: 'PENDING',
  error: 'ERROR',
  success: 'SUCCESS',
  fallback: 'FALLBACK'
}

export const stepStatuses = {
  complete: 'COMPLETE',
  ongoing: 'ONGOING'
}
