import BaseButton from 'components/base/button'
import useErrorMessages from 'hooks/use-error-messages'

import './styles.scss'

const BaseError = ({ title, message, button }) => {
  const onClick = button && button.onClick ? button.onClick : () => {}
  const errorMsg = useErrorMessages()
  const errorTitle = title ? title : errorMsg.generalErrorTitle

  return (
    <div className="base-error">
      <h1 className="center h3 fallback__title">{errorTitle}</h1>
      {message && <p className="base-error__message h2">{message}</p>}
      {button && (
        <BaseButton className="mt-20" url={button.url} onClick={onClick}>
          {button.label}
        </BaseButton>
      )}
    </div>
  )
}

export default BaseError
