import BaseError from 'components/base/error'
import BaseView from 'components/base/view'
import BaseStep from 'components/base/step'
import useErrorMessages from 'hooks/use-error-messages'
import {
  streamErrors,
  mediaDevicesError,
  flowError,
  browserStorageError
} from 'assets/js/utils/error-names'
import './styles.scss'
import _get from 'lodash/get'

const FallbackComponent = ({ error, resetErrorBoundary }) => {
  const errorMessages = useErrorMessages(resetErrorBoundary)

  const isAuthError = error.response && error.response.status === 401
  const isNotFoundError = error.response && error.response.status === 404
  const isServerError = error.response && error.response.status >= 500
  const isRequestError =
    error.response && error.response.status === 400 && error.name !== flowError
  const isStreamError = [...streamErrors].includes(error.name)
  const isMediaDevicesError = error.name === mediaDevicesError
  const isVerificationError = error.name === flowError
  const isBrowserStorageError = error.name === browserStorageError
  const { storage, server, auth, camera, media, flow, unexpected } =
    errorMessages

  const errors = [
    {
      active: isAuthError,
      render: <BaseError {...auth} />
    },
    {
      active: isNotFoundError,
      render: <BaseError button={server.button} />
    },
    {
      active: isServerError,
      render: <BaseError {...server} />
    },
    {
      active: isBrowserStorageError,
      render: <BaseError {...storage} />
    },
    {
      active: isStreamError,
      render: <BaseError {...camera} />
    },
    {
      active: isMediaDevicesError,
      render: <BaseError {...media} />
    },
    {
      active: isVerificationError,
      render: <BaseError {...flow} />
    },
    {
      active: isRequestError,
      render: (
        <BaseError
          button={{ ...errorMessages.retry.button }}
          message={_get(error, 'response.data.error', '')}
        />
      )
    }
  ]

  return (
    <BaseStep>
      <BaseView>
        <div className="fallback">
          {errors.map((error) => {
            return error.active && error.render
          })}
          {!errors.some((error) => error.active) && (
            <BaseError {...unexpected} />
          )}
        </div>
      </BaseView>
    </BaseStep>
  )
}

export default FallbackComponent
