import api from 'api'

const prefix = process.env.REACT_APP_API_PREFIX
const url = process.env.REACT_APP_API_URL || ''

export const verifyUser = async (akey) => {
  const { data } = await api.post(`${url}${prefix}/verifications/`, { akey })
  return data
}

export const confirmFlow = async () => {
  const { data } = await api.patch(`${url}${prefix}/verifications/current/`, {
    is_completed: true
  })
  return data
}
