/**
 * Utility function to display component only if condition is true
 * based on atomic `visible` & `hidden` css classes.
 * Works like v-show directive in Vue.js, ng-show in AngularJS or [hide]="!condition" in Angular 2+.
 *
 * @param {boolean} condition
 * @returns {string} of class name
 */

const visibleClass = (condition) => {
  return `${condition ? 'visible' : 'hidden'}`
}

export default visibleClass
