import SnapshotButton from 'components/SnapshotButton'

const TakePhoto = ({ onTakePhoto }) => {
  return (
    <div className="snapshot__button" onClick={onTakePhoto}>
      <SnapshotButton className="mt-20" />
      <p>Take a Snapshot</p>
    </div>
  )
}

export default TakePhoto
