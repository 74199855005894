import PropTypes, { string, number, bool } from 'prop-types'

export const document = PropTypes.shape({
  description: string,
  icon: string,
  id: number,
  is_liveness_passed: bool,
  messages: PropTypes.arrayOf(string),
  orientation: string,
  photos_uploaded: number,
  sides: number,
  title: string
})

export const step = PropTypes.shape({
  documents: PropTypes.arrayOf(document),
  id: number,
  next_step_message: string,
  position: number,
  status: PropTypes.oneOf(['pending', 'in_progress', 'complete']),
  success_message: string,
  title: string,
  type: PropTypes.oneOf(['document', 'liveness'])
})
