import { useParams } from 'react-router-dom'
import { useRef, useState, useEffect, useContext } from 'react'
import { useErrorHandler } from 'react-error-boundary'
import clsx from 'clsx'
import visibleCls from 'assets/js/utils/visible-class'
import { StepsContext } from 'context/StepsContext'

import BaseView from 'components/base/view'
import BaseStep from 'components/base/step'
import DocumentTitle from 'components/DocumentTitle'
import DocumentBody from 'components/DocumentBody/DocumentBody'
import NextStep from 'components/NextStep'

import { propTypes } from './DocumentUploadProps'

import _get from 'lodash/get'

import { stepStatuses } from 'assets/js/utils/statuses'

import { getStepById, confirmStep } from 'actions'

import useScrollTo from 'hooks/use-scroll-to'
import useLoader from 'hooks/use-loader'
import useSafeSetState from 'hooks/use-safe-set-state'
import useStepStateReducer, { actionTypes } from 'hooks/use-step-state-reducer'

import './styles.scss'

const DocumentsUpload = ({ activeStep }) => {
  const { docId } = useParams()
  /* data for current step: */
  const [step, setStep] = useState(null)
  /* data for current document;
    only properties used by the component are initialized here;
    see full data structure in assets/js/utils/propTypes.js - `document`
  */
  const [docData, setDocData] = useState({
    sides: 1,
    orientation: '',
    photos_uploaded: 0,
    messages: []
  })

  const safeSetState = useSafeSetState()
  const [stepState, dispatch] = useStepStateReducer()
  const { BaseLoader, loaderOn, loaderOff, isLoading } = useLoader(
    false,
    safeSetState
  )

  const limitedSides = useRef(false)
  const handleError = useErrorHandler()

  useScrollTo()

  const { onUpdate } = useContext(StepsContext)

  const updateStep = async () => {
    loaderOn()
    dispatch(actionTypes.hide) // to avoid 'taking photos' screen showing just before NextStep
    const step = await getStepById(activeStep.id)
    loaderOff()
    setStep(step)
  }

  const updateData = async () => {
    // logic for the current step state
    const data = step.documents.find((step) => step.id.toString() === docId)
    if (!data) return
    limitedSides.current = data.sides > 0
    setDocData(data)

    const stepComplete = step.status === 'complete'
    const allPhotosUploaded = data.photos_uploaded === data.sides

    if (!stepComplete) {
      if (limitedSides.current && allPhotosUploaded) {
        onConfirm()
      }
      if (
        !limitedSides.current ||
        (limitedSides.current && !allPhotosUploaded)
      ) {
        dispatch(actionTypes.push)
      }
    }
  }

  const onConfirm = async () => {
    const { id } = activeStep
    try {
      const data = await confirmStep(id)
      dispatch(actionTypes.complete)
      return data
    } catch (e) {
      handleError(e)
    }
  }

  const onSubmit = async () => {
    const updatedStep = await onConfirm()
    setStep(updatedStep)
  }

  useEffect(() => {
    setStep(activeStep)
  }, [activeStep])

  useEffect(() => {
    if (!step) return
    updateData()
  }, [step])

  return (
    <BaseStep>
      <BaseLoader isActive={isLoading} />
      <section className="document">
        <div
          className={clsx([
            'document__ongoing',
            visibleCls(stepState.isVisible)
          ])}
        >
          <BaseView>
            {docData.messages.length && (
              <DocumentTitle
                messages={docData.messages}
                side={docData.photos_uploaded}
                isPrecise={limitedSides.current}
              />
            )}
            <DocumentBody
              stepStatus={stepState.status}
              manualSubmit={!limitedSides.current}
              orientation={docData.orientation}
              photos={docData.photos_uploaded}
              onPhotoSent={updateStep}
              onSubmit={onSubmit}
            />
          </BaseView>
        </div>

        <div
          className={clsx([
            'document__complete',
            visibleCls(stepState.status === stepStatuses.complete)
          ])}
        >
          <NextStep
            message={_get(activeStep, 'success_message', '')}
            prompt={_get(activeStep, 'next_step_message', '')}
            onClick={onUpdate}
          />
        </div>
      </section>
    </BaseStep>
  )
}

DocumentsUpload.propTypes = propTypes

export default DocumentsUpload
