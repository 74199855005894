import { useLayoutEffect } from 'react'

const useScrollTo = (id = 'baseView') => {
  useLayoutEffect(() => {
    if (id) {
      const doc = document.getElementById(id)
      doc && doc.scrollIntoView()
    }
  })
}

export default useScrollTo
