import PropTypes, { number } from 'prop-types'

export const propTypes = {
  ratio: number,
  sizes: PropTypes.shape({
    streamWidth: number,
    streamHeight: number
  }),
  video: PropTypes.instanceOf(Element),
  id: number,
  onResult: PropTypes.func
}
