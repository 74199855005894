import { useRef } from 'react'
import BaseButton from 'components/base/button'
import BaseView from 'components/base/view'
import useScrollTo from 'hooks/use-scroll-to'
import { propTypes } from './NextStepProps'

import './styles.scss'

const NextStep = ({ message, prompt, onClick = () => {} }) => {
  const id = 'next'
  useScrollTo(id)
  const isDisabled = useRef(false)

  const clickHandler = (e) => {
    if (!isDisabled.current) {
      onClick(e)
      isDisabled.current = true
    }
  }

  return (
    <BaseView>
      <div className="next-step" id={id}>
        <img
          className="next-step__img"
          src="/img/checkmark.png"
          alt="A checkmark"
        />
        <p className="h1">{message}</p>
        {prompt && <p>{prompt}</p>}
        <BaseButton className="mt-20" onClick={clickHandler}>
          Next
        </BaseButton>
      </div>
    </BaseView>
  )
}

NextStep.propTypes = propTypes

export default NextStep
