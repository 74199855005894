import React from 'react'
import './styles.scss'

const BaseTitle = ({ children, level, className = '', ...rest }) => {
  return React.createElement(
    `h${level}`,
    { ...rest, className: `base-title h${level} ${className}`.trim() },
    children
  )
}

export default BaseTitle
