import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useErrorHandler } from 'react-error-boundary'
import useQuery from 'hooks/use-query'
import useSafeSetState from 'hooks/use-safe-set-state'
import useLoader from 'hooks/use-loader'

import _get from 'lodash/get'

import ApiWrapper from 'components/hoc/ApiWrapper'
import OnboardingScreen from 'components/OnboardingScreen'

import { verifyUser, getSteps } from 'actions'

import { StepsContextProvider } from 'context/StepsContext'

import { browserStorageError } from 'assets/js/utils/error-names'

const ApiHandler = () => {
  const safeSetState = useSafeSetState()
  const [steps, setSteps] = useState([])
  const handleError = useErrorHandler()
  const akey = useQuery().get('akey')
  const history = useHistory()
  const { BaseLoader, isLoading, loaderOff } = useLoader(true, safeSetState)

  const getToken = async () => {
    if (!window.sessionStorage) {
      handleError({ name: browserStorageError })
    }

    const jwt = sessionStorage.getItem('IDVToken')
    if (jwt) {
      // history.push('/')
      return jwt
    } else {
      const data = await initSession()
      return data
    }
  }

  const initSession = async () => {
    const apiKey = akey || ''
    try {
      const data = await verifyUser(apiKey)
      if (_get(data, 'authorization_token', '')) {
        const { authorization_token } = data
        sessionStorage.setItem('IDVToken', authorization_token)
        return authorization_token
      }
    } catch (e) {
      loaderOff()
      history.push('/')
      handleError(e)
    }
  }

  const boostData = async () => {
    const stepsData = await getSteps()
    loaderOff()
    if (stepsData) {
      safeSetState(setSteps, stepsData)
    }
  }

  const init = async () => {
    // akey query param has priority over browser storage
    let data
    if (akey) data = await initSession()
    else data = await getToken()
    if (data) boostData()
  }

  useEffect(() => {
    init()
  }, [])

  return (
    <StepsContextProvider value={{ onUpdate: boostData }}>
      <BaseLoader isActive={isLoading} />
      {Boolean(_get(steps, 'length', false)) && (
        <OnboardingScreen steps={steps} />
      )}
    </StepsContextProvider>
  )
}

export default ApiWrapper(ApiHandler)
