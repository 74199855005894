import './styles.scss'

const BaseView = ({ children }) => {
  return (
    <div className="base-view" id="baseView">
      {children}
    </div>
  )
}

export default BaseView
