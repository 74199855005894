import PropTypes from 'prop-types'
import clsx from 'clsx'
import './styles.scss'

const BaseButton = ({
  className,
  children,
  onClick,
  url,
  color,
  isDisabled
}) => {
  return (
    <button
      onClick={onClick}
      className={clsx([
        className,
        'base-button',
        `base-button--${color}`,
        { 'base-button--disabled': isDisabled }
      ])}
    >
      {url && (
        <a className="base-button__inner" href={url}>
          {children}
        </a>
      )}
      {!url && <span className="base-button__inner">{children}</span>}
    </button>
  )
}

BaseButton.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func,
  url: PropTypes.string,
  color: PropTypes.oneOf(['green', 'white'])
}

BaseButton.defaultProps = {
  theme: 'default',
  color: 'green',
  onClick: () => {}
}

export default BaseButton
