import { BrowserRouter as Router } from 'react-router-dom'
import { BrowserView, MobileView } from 'react-device-detect'
import { ErrorBoundary } from 'react-error-boundary'

import './styles/main.scss'

import FallbackComponent from 'components/errors/FallbackComponent'
import ApiHandler from 'components/ApiHandler'
import BaseView from 'components/base/view'
import useVh from 'hooks/use-vh'

import logo from 'assets/img/logo.png'

function App() {
  useVh()

  return (
    <div className="app">
      <header className="app__header">
        <img className="app__logo" src={logo} alt="IDV-Check logo" />
      </header>
      <BrowserView className="mt-50">
        <BaseView>
          <p className="center h2">
            Please, use mobile browser to proceed with validation.
          </p>
        </BaseView>
      </BrowserView>

      <MobileView>
        <ErrorBoundary FallbackComponent={FallbackComponent}>
          <main>
            <Router>
              <ApiHandler />
            </Router>
          </main>
        </ErrorBoundary>
      </MobileView>
    </div>
  )
}

export default App
