import { useState } from 'react'

const useLoadedVideo = () => {
  const [streamSizes, setStreamSizes] = useState({ width: 0, height: 0 })
  const [loaded, setLoaded] = useState(false)

  const onLoaded = (e) => {
    const { target } = e
    const { videoWidth, videoHeight } = target
    setLoaded(true)
    setStreamSizes({
      width: videoWidth,
      height: videoHeight
    })
    return { streamWidth: videoWidth, streamHeight: videoHeight }
  }

  return {
    onLoaded,
    loaded,
    streamWidth: streamSizes.width,
    streamHeight: streamSizes.height
  }
}

export default useLoadedVideo
