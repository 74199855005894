import PropTypes, { number } from 'prop-types'

export const propTypes = {
  /*
    ratio = width/height.
    used to calculate UI sizes of preview window.
    e.g. 0.75 for portrait, 1.5 for landscape
  */
  ratio: number,

  // which of device's camera to use
  camera: PropTypes.oneOf(['front', 'back']),

  /*
    shape should conform with `MediaStream constraints` object structure:
    https://developer.mozilla.org/ru/docs/Web/API/MediaDevices/getUserMedia
    if not passed and no camera prop, { video: true } is set
  */
  videoSizes: PropTypes.shape({
    width: PropTypes.oneOfType([
      number,
      PropTypes.shape({
        min: number,
        ideal: number,
        max: number
      })
    ]),
    height: PropTypes.oneOfType([
      number,
      PropTypes.shape({
        min: number,
        ideal: number,
        max: number
      })
    ]),
    frameRate: number
  })
}

export const defaultProps = {
  ratio: 1,
  camera: 'front'
}
