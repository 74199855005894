import { useRef, useEffect, useState } from 'react'
import useStream from 'hooks/use-stream'
import useLoadedVideo from 'hooks/use-loaded-video'
import useSafeSetState from 'hooks/use-safe-set-state'

import BaseLoader from 'components/base/loader'

import { defaultProps, propTypes } from './CameraStreamProps'

import clsx from 'clsx'

import './styles.scss'

import * as config from 'assets/js/utils/stream-config'

const CameraStream = ({ ratio, videoSizes, camera, onSettled, isStopped }) => {
  const safeSetState = useSafeSetState()
  const videoRef = useRef(null)
  const width = config.PREVIEW_CONTAINER_WIDTH
  const height = width / ratio

  const [stream] = useStream({ videoSizes, camera }, isStopped)

  const { onLoaded } = useLoadedVideo()

  const [isSettled, setSettled] = useState(false)

  useEffect(() => {
    if (stream) {
      videoRef.current.srcObject = stream
    }
  }, [stream])

  const onLoadedData = (e) => {
    const { streamWidth, streamHeight } = onLoaded(e)
    onSettled(videoRef.current, { width, height, streamWidth, streamHeight })
    safeSetState(setSettled, true)
  }

  return (
    <div
      className={clsx({
        'camera-stream': true,
        'camera-stream--flipped': camera === 'front'
      })}
    >
      <BaseLoader isActive={!isSettled} />
      <div className="camera-stream__video-wrapper" style={{ width, height }}>
        {isSettled && (
          <>
            <span className="camera-stream__frame camera-stream__frame--top" />
            <span className="camera-stream__frame camera-stream__frame--bottom" />
          </>
        )}
        <video
          ref={videoRef}
          id="video"
          className={clsx([
            'camera-stream__video',
            isStopped
              ? 'camera-stream__video--hidden'
              : 'camera-steram__video--visible'
          ])}
          onLoadedData={onLoadedData}
          autoPlay
          muted
          playsInline
        />
      </div>
    </div>
  )
}

CameraStream.propTypes = propTypes
CameraStream.defaultProps = defaultProps

export default CameraStream
