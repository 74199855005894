export const checkGetUserMedia = () => {
  return 'mediaDevices' in navigator && 'getUserMedia' in navigator.mediaDevices
}

export const getAvailableVideoDevice = async () => {
  /* get the first available video input */

  const devices = await navigator.mediaDevices.enumerateDevices()
  const firstVideoInput = devices.find((device) => device.kind === 'videoinput')
  return firstVideoInput
}
