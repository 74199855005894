const useErrorMessages = (resetErrorBoundary) => {
  const errorsContent = {
    generalErrorTitle: 'Something went wrong...',
    storage: {
      message: 'No support for browser storage on this device.'
    },
    server: {
      message: 'Server is temporary unavailable',
      button: {
        label: 'Try reloading page',
        onClick: () => document.location.reload()
      }
    },
    auth: {
      message: 'Please restart the process.'
    },
    retry: {
      button: {
        label: 'Please retry action',
        onClick: resetErrorBoundary
      }
    },
    camera: {
      title: 'Cannot access the camera!',
      message: `Make sure your device's camera is accessible by the app and reload the page.`
    },
    media: {
      title: 'Cannot access the camera!',
      message: `It seems that your browser does not allow using media devices.`
    },
    flow: {
      message:
        'Error occurred in application flow. Please update your credentials and restart the proccess.'
    },
    unexpected: {
      message: 'Unexpected error occurred',
      button: {
        label: 'Try reloading page',
        onClick: () => document.location.reload()
      }
    }
  }
  return errorsContent
}

export default useErrorMessages
