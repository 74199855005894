import { useState, useEffect, useContext } from 'react'
import { getStepById, confirmStep } from 'actions'
import BaseStep from 'components/base/step'
import LiveCheck from 'components/LiveCheck'
import NextStep from 'components/NextStep'
import { StepsContext } from 'context/StepsContext'
import getRandomIndex from 'assets/js/helpers/get-rand-ind'
import useScrollTo from 'hooks/use-scroll-to'

import { propTypes } from './AntispoofStepProps'

const AntispoofStep = ({ activeStep }) => {
  useScrollTo()
  const [onGoingCheck, setOnGoingCheck] = useState()
  const [step, setStep] = useState(null)
  const [isComplete, setComplete] = useState(false)
  const [isFailed, setFailed] = useState(false)

  const { onUpdate } = useContext(StepsContext)

  const updateStep = async () => {
    const step = await getStepById(activeStep.id)
    setStep(step)
  }

  useEffect(() => {
    setStep(activeStep)
  }, [activeStep])

  useEffect(() => {
    if (!step || step.status === 'complete') return

    // handle case of broken flow due to server error
    // find option with partially uploaded images but in pending status
    const unfinished = step.documents.find(
      (step) => step.is_liveness_passed === null && step.photos_uploaded
    )
    if (unfinished) onStepComplete()

    // get available antispoof options out of pending
    const stepsToDo = step.documents.filter(
      (item) => item.is_liveness_passed === null
    )
    // no options means we need to complete the step
    if (!stepsToDo.length) {
      setFailed(false)
      setComplete(true)
    } else {
      // randomly choose the next antispoof check
      const ind = getRandomIndex(stepsToDo.length - 1)
      setOnGoingCheck(stepsToDo[ind])
    }
  }, [step])

  const onStepComplete = async () => {
    if (activeStep.status !== 'complete') {
      try {
        // check if antispoof successful
        await confirmStep(activeStep.id)
        setComplete(true) // this will display NextStep screen to move forward with page reload
      } catch (e) {
        // antispoof failed; need to get recent data
        setFailed(true) // this will display NextStep screen before user proceeds to next anitispoff option
        updateStep() // load actual steps data in background
      }
    }
  }

  const goToNextCheck = (e) => {
    e.preventDefault()
    setFailed(false)
  }

  return (
    <BaseStep>
      {!isComplete && !isFailed && (
        <LiveCheck data={onGoingCheck} next={onStepComplete} />
      )}
      {isComplete && (
        <NextStep
          message={activeStep.success_message}
          prompt={activeStep.next_step_message}
          onClick={onUpdate}
        />
      )}
      {isFailed && (
        <NextStep
          message={activeStep.success_message}
          onClick={goToNextCheck}
        />
      )}
    </BaseStep>
  )
}

AntispoofStep.propTypes = propTypes

export default AntispoofStep
