import BaseButton from 'components/base/button'

const AcceptPhoto = ({ onAccept }) => {
  return (
    <div className="mt-20 mb-20 mr-10 ml-10">
      <BaseButton onClick={onAccept}>Accept Image</BaseButton>
    </div>
  )
}

export default AcceptPhoto
