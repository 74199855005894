import { useState, useEffect } from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { mediaDevicesError } from 'assets/js/utils/error-names'
import { checkGetUserMedia } from 'assets/js/helpers/stream-helpers'
import useSafeSetState from 'hooks/use-safe-set-state'

/**
 * Get MediaStream from camera via helper async function `getStream`.
 * Calculate stream sizes (used for painting stream on canvas).
 *
 * @param {Object} options - data for native MediaStream `constraints` parameter
 * @param {any} stopTrigger - a dependency (coming from parent component) on which stream stop/resume relies
 * @returns {Array} [stream: MediaStream, getStream: function]
 */
const useStream = (options, stopTrigger) => {
  const [stream, setStream] = useState(null)
  const handleError = useErrorHandler()
  const setSafeState = useSafeSetState()

  const parseConstraints = ({ camera, deviceId, videoSizes }) => {
    let constraints = {}

    let video = {}

    if (videoSizes) {
      /*
        e.g. video: { width: 1000, height: 500 }; 
        for more details, look up components/CameraCapture/CameraCaptureProps.js
      */
      video = { ...videoSizes }
    }

    // force camera type if present
    if (camera) {
      if (camera === 'front') {
        Object.assign(video, { facingMode: 'user' })
      } else if (camera === 'back') {
        Object.assign(video, { facingMode: { exact: 'environment' } })
      }
    }

    // force device by id if present
    if (deviceId) {
      Object.assign(video, { deviceId })
    }

    // if no video params present, just set video: true
    if (!Object.keys(video).length) video = true

    constraints.video = { ...video }
    return constraints
  }

  const getStream = async () => {
    if (!checkGetUserMedia()) {
      handleError({ name: mediaDevicesError })
    }

    const constraints = parseConstraints(options)
    navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
      // possible workaround for unexpected MediaStram crash in latest iOS
      // const clonedStream = stream.clone()
      setSafeState(setStream, stream)
    }, handleError)
  }

  const stopTracks = () => {
    stream &&
      stream.getTracks().forEach(function (track) {
        track.stop()
      })
    setStream(null)
  }

  useEffect(() => {
    return () => {
      stopTracks()
    }
  }, [])

  useEffect(() => {
    if (stopTrigger) {
      stopTracks()
    } else {
      getStream()
    }
  }, [stopTrigger])

  return [stream, getStream]
}

export default useStream
