import { useEffect } from 'react'
import { useErrorHandler } from 'react-error-boundary'
import { useHistory } from 'react-router-dom'
import useSafeSetState from 'hooks/use-safe-set-state'
import api from 'api'
import _get from 'lodash/get'

const ApiWrapper = (ApiHandler) => {
  /* Component is responsible for centralized error handling of api requests */

  const Interceptors = (props) => {
    const handleError = useErrorHandler()
    const history = useHistory()
    const safeSetState = useSafeSetState()

    useEffect(() => {
      api.interceptors.request.use((request) => {
        const token = sessionStorage.getItem('IDVToken')
        if (token) request.headers.Authorization = token
        return request
      })

      api.interceptors.response.use(
        (response) => {
          return response
        },
        (err) => {
          const status = _get(err, 'response.status', 500)

          console.warn(`Error was intercepted: ${err.message}`)
          console.warn(`Response: ${_get(err, 'response.data.error', '')}`)

          /* Handle errors */

          // consider auth, server, not found errors as global i.e. to be handled by the outer ErrorBoundary
          if (status === 401) {
            handleError(err)
            history.push('/')
          } else if (status === 404 || status >= 500) {
            safeSetState(handleError, err)
          } else if (status === 400) {
            // errors for inner ErrorBoundary; should be caught in corresponding component
            throw err
          }
        }
      )
    }, [])

    return <ApiHandler {...props} />
  }
  return Interceptors
}

export default ApiWrapper
