import './styles.scss'

const BaseLoader = ({ isActive }) => {
  return (
    <span
      className={isActive ? `base-loader base-loader--active` : `base-loader`}
    >
      <span className="base-loader__inner">
        <span className="base-loader__animated" />
      </span>
    </span>
  )
}

export default BaseLoader
