import api from 'api'

const prefix = process.env.REACT_APP_API_PREFIX
const url = process.env.REACT_APP_API_URL || ''

export const getSteps = async () => {
  const data = await api.get(`${url}${prefix}/steps/`)
  if (data) return data.data
}

export const getStepById = async (id) => {
  const data = await api.get(`${url}${prefix}/steps/${id}/`)
  if (data) {
    return data.data
  }
}

export const confirmStep = async (id) => {
  const data = await api.patch(`${url}${prefix}/steps/${id}/`, { status: 'complete' })
  if (data) {
    return data.data
  }
}
