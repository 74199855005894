import { useEffect, useRef } from 'react'
import { useErrorHandler } from 'react-error-boundary'

import TakePhoto from 'components/TakePhoto'
import AcceptPhoto from 'components/AcceptPhoto'
import SubmitButton from 'components/SubmitButton'
import RetakeButton from 'components/RetakeButton'

import { postDocumentPhotos } from 'actions'

import useCoverCanvas from 'hooks/use-cover-canvas'
import { PREVIEW_WIDTH } from 'assets/js/utils/stream-config'

import { propTypes } from './SnapshotProps'

import useLoader from 'hooks/use-loader'
import useSnapshotStateReducer, {
  actionTypes
} from 'hooks/use-snapshot-state-reducer'
import useSafeSetState from 'hooks/use-safe-set-state'

import clsx from 'clsx'

import './styles.scss'

const Snapshot = ({
  sizes,
  video,
  side,
  manualSubmit,
  docId,
  onPhotoSent,
  onSubmit
}) => {
  const { width, height, streamWidth, streamHeight } = sizes
  const ratio = (width / height).toFixed(2)
  const { canvas, ctx, setCanvasParams } = useCoverCanvas(ratio)
  const imageRef = useRef(null)
  const finalBlobRef = useRef(null)
  const safeSetState = useSafeSetState()

  const { BaseLoader, isLoading, loaderOn, loaderOff } = useLoader(
    false,
    safeSetState
  )
  const [state, dispatch] = useSnapshotStateReducer()

  const handleError = useErrorHandler()

  useEffect(() => {
    imageRef.current.onload = function () {
      dispatch(actionTypes.preview)
      drawOriginalImage()
    }
  }, [])

  const onTakePhoto = () => {
    loaderOn()
    showPreview()
  }

  const drawImage = (canvasParams, isPreview) => {
    const { offsetX, offsetY, imageWidth, imageHeight } = canvasParams
    try {
      ctx.current.drawImage(video, offsetX, offsetY, imageWidth, imageHeight)
      canvas.current.toBlob((blob) => {
        if (!imageRef.current) return
        if (isPreview) {
          imageRef.current.src = URL.createObjectURL(blob)
        } else {
          finalBlobRef.current = blob
          loaderOff()
        }
      })
    } catch (e) {
      console.error(e)
      handleError()
    }
  }

  const drawOriginalImage = () => {
    const acceptParams = setCanvasParams({ streamWidth, streamHeight })
    drawImage(acceptParams)
  }

  const showPreview = () => {
    let { streamWidth, streamHeight } = sizes
    streamHeight = (PREVIEW_WIDTH / streamWidth) * streamHeight
    streamWidth = PREVIEW_WIDTH
    const initResult = setCanvasParams({ streamWidth, streamHeight })

    drawImage(initResult, true)
  }

  const acceptImage = async () => {
    loaderOn()
    const formData = new FormData()
    formData.append('photo', finalBlobRef.current, 'image.png')
    formData.append('document_id', docId)
    formData.append('side', side)

    try {
      await postDocumentPhotos(formData)
      /* 
        if the document's sides are specified, let backend decide when step can be completed;
        if not, i.e. `manualSubmit` prop is true, take photos until user submits the step
        */
      loaderOff()
      onPhotoSent()
      if (manualSubmit) {
        dispatch(actionTypes.submit)
      }
    } catch (e) {
      handleError(e)
    }
  }

  const onRetake = () => {
    dispatch(actionTypes.hide)
  }

  return (
    <div className="snapshot">
      <BaseLoader isActive={isLoading} />
      {!state.isVisible && <TakePhoto onTakePhoto={onTakePhoto} />}

      {state.isVisible && (
        <>
          {!state.isSubmit && <AcceptPhoto onAccept={acceptImage} />}
          <RetakeButton
            label={state.isSubmit ? 'Next photo' : 'Retake photo'}
            onRetake={onRetake}
          />
          {state.isSubmit && <SubmitButton onSubmit={onSubmit} />}
        </>
      )}
      <div
        className={clsx({
          snapshot__wrapper: true,
          'snapshot__wrapper--visible': state.isVisible
        })}
        style={{ width, height }}
      >
        <img ref={imageRef} className="snapshot__img" alt="Document photo" />
        <canvas ref={canvas} id="canvas" className="snapshot__canvas" />
      </div>
    </div>
  )
}

Snapshot.propTypes = propTypes

export default Snapshot
