import PropTypes, { number, func, bool, string } from 'prop-types'

export const propTypes = {
  sizes: PropTypes.shape({
    width: number,
    height: number,
    streamWidth: number,
    streamHeight: number
  }),
  video: PropTypes.instanceOf(Element),
  sideToStart: number,
  manualSubmit: bool,
  docId: string,
  onPhotoSent: func,
  onSubmit: func
}
