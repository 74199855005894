import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import BaseTitle from 'components/base/title'
import BaseImage from 'components/base/image'
import { document } from 'assets/js/utils/propTypes'
import './styles.scss'

const DocOption = ({ document }) => {
  const { id, title, icon, description } = document
  const history = useHistory()
  const url = `/document/${id}`
  const navigate = () => {
    history.push(url)
  }
  return (
    <article className="doc-option" onClick={navigate}>
      <div className="doc-option__icon">
        <BaseImage src={icon} alt={title} />
      </div>
      <BaseTitle level={2}>
        <Link to={url}>{title}</Link>
      </BaseTitle>
      <p className="doc-option__desc">{description}</p>
    </article>
  )
}

DocOption.propTypes = { document }

export default DocOption
