import { useReducer } from 'react'

export const actionTypes = {
  hide: 'HIDE',
  preview: 'PREVIEW',
  submit: 'SUBMIT'
}

const initialState = {
  isVisible: false,
  isSubmit: null
}

const stateReducer = (_, action) => {
  switch (action) {
    case actionTypes.hide:
      return {
        ...initialState
      }
    case actionTypes.preview:
      return {
        isVisible: true,
        isSubmit: false
      }
    case actionTypes.submit:
      return {
        isVisible: true,
        isSubmit: true
      }
    default:
      throw Error('Not valid action was passed to stateReducer.')
  }
}

const useSnapshotStateReducer = () => {
  return useReducer(stateReducer, initialState)
}

export default useSnapshotStateReducer
