import PropTypes, { bool, func, number } from 'prop-types'
import { stepStatuses } from 'assets/js/utils/statuses'

export const propTypes = {
  manualSubmit: bool,
  stepStatus: PropTypes.oneOf([stepStatuses.ongoing, stepStatuses.complete]),
  photos: number, // number of photos currently uploaded for the document
  onPhotoSent: func, // a callback to be sent to parent
  onSubmit: func // a callback to be sent to parent
}
