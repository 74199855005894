import { useState, useEffect, useRef } from 'react'
import BaseLoader from 'components/base/loader'
import PropTypes from 'prop-types'

import './styles.scss'

const Counter = ({ onEnded }) => {
  const [counter, setCounter] = useState(3)
  const interval = useRef(null)

  useEffect(() => {
    interval.current = setInterval(() => {
      setCounter((state) => (state -= 1))
    }, 1000)
  }, [])

  useEffect(() => {
    if (!counter) {
      clearInterval(interval.current)
      onEnded()
    }
  }, [counter])

  return (
    <div className="counter">
      <BaseLoader isActive />
      <span className="counter__message">
        start in
        <span className="counter__counter">{counter}</span>
      </span>
    </div>
  )
}

Counter.propTypes = {
  onEnded: PropTypes.func
}

export default Counter
