import { useState, useEffect } from 'react'
import BaseTitle from 'components/base/title'
import { propTypes } from './DocumentTitleProps'

const DocumentTitle = ({ messages, side, isPrecise }) => {
  const [title, setTitle] = useState('')

  useEffect(() => {
    if (isPrecise || side < 1) {
      setTitle(messages[side])
    } else {
      setTitle(messages[1])
    }
  }, [side])

  return (
    <BaseTitle level={2} className="document__title">
      {title}
    </BaseTitle>
  )
}

DocumentTitle.propTypes = propTypes

export default DocumentTitle
