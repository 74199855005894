import { useState, useDebugValue } from 'react'
import BaseLoader from 'components/base/loader'

const useLoader = (initValue, safeSetState) => {
  /**
   * Imports and return BaseLoader and local state to manage it visibility.
   * As an optional argument, accepts a "safe dispatch" function
   * which should be used instead of regular setState dispatcher.
   * This function takes into consideration component's isMounted ref
   * (see hooks/use-safe-set-state.js for details).
   * Use this second argument if you have loaderOn/loaderOff used after async action.
   *
   * @param {bool} initValue - initial value for showing loader; fallback to false if not specified
   * @param {function} safeSetState – a function to replace regular setState dispatcher
   * @return {object}
   */

  const [isLoading, setLoading] = useState(initValue ? initValue : false)
  useDebugValue(isLoading ? 'on' : 'off')

  const loaderOn = () => {
    if (safeSetState) {
      safeSetState(setLoading, true)
    } else setLoading(true)
  }

  const loaderOff = () => {
    if (safeSetState) {
      safeSetState(setLoading, false)
    } else setLoading(false)
  }

  return { BaseLoader, isLoading, loaderOn, loaderOff }
}

export default useLoader
