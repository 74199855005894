import DocumentsUpload from 'components/DocumentsUpload'
import Dashboard from 'components/Dashboard'
import AntispoofStep from 'components/AntispoofStep'

const routes = [
  {
    path: '/document/:docId',
    component: DocumentsUpload
  },
  {
    path: '/document',
    exact: true,
    component: Dashboard
  },
  {
    path: '/liveness',
    exact: true,
    component: AntispoofStep
  }
]

export default routes
