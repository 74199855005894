import BaseButton from 'components/base/button'

import './styles.scss'

const SubmitButton = ({ onSubmit }) => {
  return (
    <div className="mt-20 mb-20 mr-10 ml-10">
      <BaseButton onClick={onSubmit}>Submit</BaseButton>
    </div>
  )
}

export default SubmitButton
