import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import DocOption from 'components/DocOption'
import { propTypes } from './DashboardProps'

import './styles.scss'

const Dashboard = ({ activeStep }) => {
  const history = useHistory()

  useEffect(() => {
    if (!activeStep) return
    const { documents, status } = activeStep
    if (status === 'in_progress') {
      // find unfinished document upload
      const doc = documents.find((doc) => doc.photos_uploaded)
      if (doc) history.push(`/document/${doc.id}`)
    }
  }, [activeStep])

  return (
    <section className="dashboard">
      {activeStep && (
        <>
          <h1 className="center">{activeStep.title}</h1>
          {activeStep.documents.map((item) => {
            return <DocOption document={item} key={item.id} />
          })}
        </>
      )}
    </section>
  )
}

Dashboard.propTypes = propTypes

export default Dashboard
