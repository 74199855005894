import { useEffect } from 'react'
import setCssVariable from 'assets/js/helpers/set-css-variable'

const useVh = () => {
  const onResize = () => {
    setCssVariable('--vh')
  }

  const onOrientationChange = () => {
    setTimeout(() => {
      setCssVariable('--vh-static')
    }, 200)
  }

  useEffect(() => {
    setCssVariable('--vh')
    setCssVariable('--vh-static')
    window.addEventListener('resize', onResize)
    window.addEventListener('orientationchange', onOrientationChange)

    return () => {
      window.removeEventListener('resize', onResize)
      window.removeEventListener('orientationchange', onOrientationChange)
    }
  }, [])
}

export default useVh
