import { ReactComponent as Camera } from 'assets/img/camera.svg'

import './styles.scss'

const SnapshotButton = () => {
  return (
    <button className="snapshot-button">
      <span className="snapshot-button__inner">
        <Camera />
      </span>
    </button>
  )
}

export default SnapshotButton
