export const overconstrainedError = 'OverconstrainedError'

export const streamErrors = [
  'NotAllowedError',
  'NotFoundError',
  'NotReadableError',
  overconstrainedError
]

export const mediaDevicesError = 'MediaDevicesNotSupportedError'

export const flowError = 'InnerFlowError'

export const browserStorageError = 'BrowserStorageError'
