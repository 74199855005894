import { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import BaseTitle from 'components/base/title'
import CameraStream from 'components/CameraStream'
import Multishot from 'components/MultiShot'
import BaseLoader from 'components/base/loader'
import BaseView from 'components/base/view'
import { IDEAL_STREAM_WIDTH_FRONT } from 'assets/js/utils/stream-config'
import getRatioByOrientation from 'assets/js/utils/orientations'

import { document } from 'assets/js/utils/propTypes'

import './styles.scss'

const LiveCheck = ({ data, next }) => {
  const videoRef = useRef(null)
  const [loadedState, setLoaded] = useState(false)
  const [isDone, setDone] = useState(false)
  const [sizes, setSizes] = useState({
    streamWidth: 0,
    streamHeight: 0,
    width: 0,
    height: 0
  })

  const onSettled = (video, sizes) => {
    videoRef.current = video
    setSizes(sizes)
    setLoaded(true)
  }

  const onDataSent = () => {
    setDone(true)
    next()
  }

  return (
    <section className="live-check">
      <BaseLoader isActive={isDone} />
      {data && data.messages && (
        <BaseView>
          <BaseTitle level={1} className="live-check__title">
            {data.messages[0]}
          </BaseTitle>
          <CameraStream
            camera="front"
            ratio={getRatioByOrientation(data.orientation)}
            videoSizes={{ width: IDEAL_STREAM_WIDTH_FRONT }}
            onSettled={onSettled}
            isStopped={isDone}
          />
        </BaseView>
      )}
      {loadedState && data && (
        <Multishot
          sizes={{
            streamWidth: sizes.streamWidth,
            streamHeight: sizes.streamHeight
          }}
          ratio={getRatioByOrientation(data.orientation)}
          video={videoRef.current}
          id={data.id}
          onResult={onDataSent}
        />
      )}
    </section>
  )
}

LiveCheck.propTypes = {
  document,
  next: PropTypes.func
}

export default LiveCheck
