import { useRef, useEffect } from 'react'

const useSafeSetState = () => {
  /**
   * Prepare to track if component mounted.
   * Creates a function to update local state safely.
   * Use it instead of regular setState dispatch if you call it after async action.
   * @return {func}
   */

  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])

  const safeSetState = (func, value) => {
    if (!isMounted.current) return
    func(value)
  }

  return safeSetState
}

export default useSafeSetState
