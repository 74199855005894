import BaseButton from 'components/base/button'

const RetakeButton = ({ label, onRetake }) => {
  return (
    <div className="ml-10 mr-10">
      <BaseButton color="white" onClick={onRetake}>
        {label}
      </BaseButton>
    </div>
  )
}

export default RetakeButton
