import clsx from 'clsx'
import { propTypes } from './StepsProps'
import './styles.scss'

const Steps = ({ steps, current }) => {
  const width = (100 / (steps - 1)) * current

  return (
    <div className="steps" id="steps">
      <span className="steps__line">
        <span className="steps__progress" style={{ width: `${width}%` }} />
      </span>
      <ul className="steps__list">
        {Array.from(Array(steps)).map((step, ind) => {
          return (
            <li
              key={ind}
              className={clsx({
                steps__item: true,
                'steps__item--inactive': ind > current
              })}
            >
              <span>{ind + 1}</span>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

Steps.propTypes = propTypes

export default Steps
