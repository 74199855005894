import { useEffect, useRef, useState } from 'react'
import useCoverCanvas from 'hooks/use-cover-canvas'
import { postDocumentPhotos } from 'actions'
import { useErrorHandler } from 'react-error-boundary'

import Counter from 'components/Counter'
import { propTypes } from './MultiShotProps'

import './styles.scss'

const TOTAL = 5
const INTERVAL = 600

const MultiShot = ({ ratio, sizes, video, id, onResult }) => {
  const { streamWidth, streamHeight } = sizes
  const { canvas, ctx, canvasParams, setCanvasParams } = useCoverCanvas(ratio)

  const [showCounter, setCounterVisibility] = useState(true)
  const photosSent = useRef(0)
  const [allSent, setAllSent] = useState(false)
  const handleError = useErrorHandler()

  useEffect(() => {
    if (!id) return
    setCanvasParams({ streamWidth, streamHeight })
  }, [id])

  useEffect(() => {
    if (!allSent) return
    onResult()
  }, [allSent])

  const launchCheck = () => {
    let counter = 0
    const interval = setInterval(() => {
      counter++
      getSnapshot()
      if (counter === TOTAL) {
        clearInterval(interval)
      }
    }, INTERVAL)
  }

  const uploadImage = async (blob) => {
    const formData = new FormData()
    formData.append('photo', blob, 'image.png')
    formData.append('document_id', id)
    formData.append('side', 0)
    try {
      await postDocumentPhotos(formData)
    } catch (e) {
      /* SIC! this is a document upload error; not antispoof check error.

        400 error will just be caught silently at the background.
        Server error will be intercepted globally and user will be prompted to reload the page.
        Less than default (5) photos are OK for the liveness check,
        so we just proceed with the flow instead of handling error in UI.
      */
      // console.log(`Error during photo upload: ${e}`)
    } finally {
      photosSent.current++
      if (photosSent.current === TOTAL) setAllSent(true)
    }
  }

  const getSnapshot = async () => {
    const { offsetX, offsetY, imageWidth, imageHeight } = canvasParams
    ctx.current.drawImage(video, offsetX, offsetY, imageWidth, imageHeight)

    try {
      canvas.current.toBlob(uploadImage)
    } catch (e) {
      handleError(e)
    }
  }

  const onEnded = () => {
    setCounterVisibility(false)
    launchCheck()
  }

  return (
    <div className="multi-shot">
      {showCounter && <Counter onEnded={onEnded} />}
      <canvas ref={canvas} id="canvas" className="multi-shot__canvas" />
    </div>
  )
}

MultiShot.propTypes = propTypes

export default MultiShot
