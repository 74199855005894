import { useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { IDEAL_STREAM_WIDTH_BACK } from 'assets/js/utils/stream-config'
import getRatioByOrientation from 'assets/js/utils/orientations'
import { stepStatuses } from 'assets/js/utils/statuses'
import { propTypes } from './DocumentBodyProps'

import CameraStream from 'components/CameraStream'
import Snapshot from 'components/Snapshot'

const DocumentBody = ({
  manualSubmit,
  orientation,
  stepStatus,
  photos,
  onPhotoSent,
  onSubmit
}) => {
  const { docId } = useParams()
  const videoRef = useRef(null)
  const [isSettled, setSettled] = useState(false)
  const [sizes, setSizes] = useState({
    streamWidth: 0,
    streamHeight: 0,
    width: 0,
    height: 0
  })

  const onSettled = (video, sizes) => {
    videoRef.current = video
    setSizes(sizes)
    setSettled(true)
  }

  return (
    <div className="document__wrapper">
      <div className="document__camera">
        {stepStatus && (
          <CameraStream
            camera="back"
            ratio={getRatioByOrientation(orientation)}
            videoSizes={{ width: IDEAL_STREAM_WIDTH_BACK }}
            onSettled={onSettled}
            isStopped={stepStatus === stepStatuses.complete}
          />
        )}
      </div>

      {isSettled && (
        <Snapshot
          video={videoRef.current}
          sizes={sizes}
          manualSubmit={manualSubmit}
          docId={docId}
          side={photos + 1}
          onPhotoSent={onPhotoSent}
          onSubmit={onSubmit}
          key={manualSubmit ? null : photos}
        />
      )}
    </div>
  )
}

DocumentBody.propTypes = propTypes

export default DocumentBody
