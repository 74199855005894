const getRatioByOrientation = (orientation) => {
  const orientationsMap = {
    passport: 1.33,
    idcard: 1.33,
    A4: 0.75
  }
  return orientationsMap[orientation]
}

export default getRatioByOrientation
